import React, { useEffect, useState } from 'react';
import '../styles/blog.css';

function Blog() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch('https://paragraph.xyz/api/blogs/rss/@mage')
            .then(response => response.text())
            .then(data => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, "text/xml");
    
                const items = Array.from(xmlDoc.querySelectorAll('item')).map(item => {
                    const titleElement = item.querySelector('title');
                    const pubDateElement = item.querySelector('pubDate');
                    const enclosureElement = item.querySelector('enclosure');
                    const contentElement = item.getElementsByTagNameNS('*', 'encoded')[0];
    
                    return {
                        title: titleElement ? titleElement.textContent : '',
                        pubDate: pubDateElement ? pubDateElement.textContent : '',
                        enclosureUrl: enclosureElement ? enclosureElement.getAttribute('url') : '',
                        content: contentElement ? contentElement.textContent : ''                      
                    };
                });
    
                setItems(items);
            });
    }, []);
    
    return (
        <div className="blog-container">
            {items.map((item, index) => (
                <div key={index} className="post">
                    <h2 className="post-title">{item.title}</h2>
                    <p className="post-date">{item.pubDate}</p>
                    <img className="post-image" src={item.enclosureUrl} alt="" />
                    <div className="post-content" dangerouslySetInnerHTML={{ __html: item.content }} />                  
                </div>
            ))}
        </div>
    );
}

export default Blog;