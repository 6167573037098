import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartLine, faBlog, faUsers, faHatWizard } from '@fortawesome/free-solid-svg-icons';
import '../styles/navbar.css';

const Navbar = () => {
    return (
        <header className="navbar">
            <nav className="links">
                <div className="nav-link-container">
                    <NavLink to="/" exact className="nav-link nav-link-item" activeClassName='active'><FontAwesomeIcon icon={faHome} />Home</NavLink>
                    <NavLink to="/analytics" className="nav-link nav-link-item" activeClassName='active'><FontAwesomeIcon icon={faChartLine} />Analytics</NavLink>
                    <NavLink to="/blog" className="nav-link nav-link-item" activeClassName='active'><FontAwesomeIcon icon={faBlog} />Blog</NavLink>
                    <a href="https://warpcast.com/~/channel/mage/recent" target="_blank" rel="noopener noreferrer" className="nav-link nav-link-item"><FontAwesomeIcon icon={faUsers} />Social</a>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;