import { ApolloClient, InMemoryCache } from '@apollo/client';

const client1 = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/decentra-web/decentraweb/',
  cache: new InMemoryCache()
});

const client2 = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/decentra-web/decentrawebpolygon/',
  cache: new InMemoryCache()
});

export { client1, client2 };