import React from 'react';

function Footer() {
    return (
        <footer>
            <iframe 
                title="Paragraph.xyz @mage"
                src="https://paragraph.xyz/@mage/embed?minimal=true" 
                width="480" 
                height="45" 
                style={{border:"1px solid #EEE", background:"white"}} 
                frameBorder="0" 
                scrolling="no"
            />
        </footer>
    );
}

export default Footer;