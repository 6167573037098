import React from 'react';

const Home = () => { 
    return (
        <div className="iframe-container">
            <iframe 
                id="decentraweb-minting-widget" 
                title="Mint your Web3 Subdomain" 
                src="https://dns.decentraweb.org/registrar/cm"
            ></iframe>
        </div>
    ); 
}

export default Home;