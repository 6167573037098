import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Home from '../pages';
import Blog from '../pages/blog';
import Navbar from './Navbar';
import Footer from './Footer';
import '../styles/global.css';
import Analytics from '../pages/analytics';


function App() {
    return ( 
        <Router> 
            <Navbar />
            <main>
                <Routes> 
                    <Route exact path='/' element={<Home />} /> 
                    <Route path='/analytics' element={<Analytics />} /> 
                    <Route path='/blog' element={<Blog />} /> 
                </Routes> 
            </main>
            <Footer />
        </Router> 
    ); 
} 

export default App;